"use client";

import { useContext } from "react";
import { useState } from "react";
import { createContext, ReactNode } from "react";

const instructions = [
  "Gaze upon the image card and let the emotions, memories, or feelings it stirs flow into the textbox below.",
  "Study the image card and describe what emotions, memories, or sensations it conjures up in the textbox.",
  "Peek at the image card and share in the textbox below the feelings, memories, or vibes it brings to mind.",
  "Look at the image card and pour the evoked emotions, memories, or feelings into the textbox below.",
  "Observe the image card and type out the emotions, memories, or sensations it awakens in the textbox.",
  "Take in the image card and describe in the textbox the emotions, memories, or feelings it sparks.",
  "Glance at the image card and express the emotions, memories, or feelings it elicits in the textbox below.",
  "View the image card and jot down the emotions, memories, or sensations it evokes in the textbox.",
  "Inspect the image card and fill the textbox with the emotions, memories, or feelings it prompts.",
  "Behold the image card and let the textbox capture the emotions, memories, or sensations it ignites.",
  "Examine the image card and transcribe in the textbox below the emotions, memories, or feelings it arouses.",
  "Look at the image card and convey the emotions, memories, or sensations it brings forth in the textbox.",
  "Observe the image card and describe the feelings, memories, or emotions it inspires in the textbox below.",
  "Gaze at the image card and let the textbox hold the emotions, memories, or feelings it evokes.",
  "Study the image card and type the emotions, memories, or sensations it stirs in the textbox below.",
  "Take in the image card and express in the textbox the feelings, memories, or emotions it conjures up.",
  "Glance at the image card and fill the textbox with the emotions, memories, or feelings it evokes.",
  "View the image card and describe the emotions, memories, or sensations it brings to mind in the textbox.",
  "Inspect the image card and write down the emotions, memories, or feelings it triggers in the textbox below.",
  "Behold the image card and let the textbox capture the emotions, memories, or sensations it stirs.",
];

const getRandomLine = () => {
  return instructions[Math.floor(Math.random() * instructions.length)];
};

export interface StreamEntry {
  id: string;
  type: string;
  data: any;
  role: string; // "user" | "bot",
  action: string; // "flip-start" | "flip" | "idea-start" | "idea" | "qustion" | "prompt" | "your-turn"
  isIdea?: boolean;
  category?: string; // "challenge" | "idea" | "prompt" | "question",
  isAction?: boolean;
}

interface SessionContextType {
  id?: string;
  setId?: (arg0: string) => void;
  botIdeaId?: string;
  setBotIdeaId?: (arg0: string) => void;
  title?: string;
  setTitle?: (arg0: string) => void;
  isLoading?: boolean;
  setIsLoading?: (arg0: boolean) => void;
  streamOfThought?: string[];
  setStreamOfThought?: (arg0: string) => void;
  challenge?: string;
  setChallenge?: (arg0: string) => void;
  challengeAccepted?: boolean;
  setChallengeAccepted?: (arg0: boolean) => void;
  inputState?: string;
  setInputState?: (arg0: string) => void;
  stream?: StreamEntry[];
  setStream?: (arg0: StreamEntry[]) => void;
  actions?: StreamEntry[];
  setActions?: (arg0: StreamEntry[]) => void;
  streamAccepted?: boolean;
  setStreamAccepted?: (arg0: boolean) => void;
  state?: string;
  setState?: (arg0: string) => void;
  sessionState?: string;
  setSessionState: (arg0: string) => void;
  shownEncouragement?: boolean;
  setShownEncouragement?: (arg0: boolean) => void;
  shownCards?: boolean;
  setShownCards?: (arg0: boolean) => void;
  challengeData: {
    whatIfWord: string;
    whatIfImage: string;
    whatIfImageWord: string;
    idea?: string;
    prompts?: string;
    questions?: string;
  };
  setChallengeData: (arg0: {
    whatIfWord?: string;
    whatIfImage?: string;
    whatIfImageWord?: string;
    idea?: string;
    prompts?: string;
    questions?: string;
  }) => void;
  personality?: string;
  setPersonality?: (arg0: string) => void;
  selectedIdea: string;
  setSelectedIdea: (arg0: string) => void;
  showHistory?: boolean;
  setShowHistory?: (arg0: boolean) => void;
  challengeIsPublic?: boolean;
  setChallengeIsPublic?: (arg0: boolean) => void;
  randomLine?: string;
}

export const SessionContext = createContext<SessionContextType>({
  id: "",
  setId: () => {},
  botIdeaId: "",
  setBotIdeaId: () => {},
  isLoading: false,
  setIsLoading: () => {},
  title: "",
  setTitle: () => {},
  streamOfThought: [],
  setStreamOfThought: () => {},
  challenge: "",
  setChallenge: () => {},
  challengeAccepted: false,
  setChallengeAccepted: () => {},
  inputState: "challenge",
  setInputState: () => {},
  stream: [],
  setStream: () => {},
  actions: [],
  setActions: () => {},
  streamAccepted: false,
  setStreamAccepted: () => {},
  state: "start",
  setState: () => {},
  sessionState: "challenge",
  setSessionState: () => {},
  shownEncouragement: false,
  setShownEncouragement: () => {},
  shownCards: false,
  setShownCards: () => {},
  challengeData: {
    whatIfWord: "",
    whatIfImage: "",
    whatIfImageWord: "",
    idea: "",
    prompts: "",
    questions: "",
  },
  setChallengeData: () => {},
  personality: "articulate",
  setPersonality: () => {},
  selectedIdea: "",
  setSelectedIdea: () => {},
  showHistory: false,
  setShowHistory: () => {},
  challengeIsPublic: false,
  setChallengeIsPublic: () => {},
  randomLine: "",
});

interface SessionProviderProps {
  children: ReactNode;
  welcome?: string;
  serverPersonality?: string;
  session?: any;
  selectedIdeaId?: string;
  initialBotIdeaId?: string;
}

export const SessionContextProvider = SessionContext.Provider;

export function SessionProvider({
  children,
  welcome = "",
  serverPersonality = "articulate",
  session = {},
  selectedIdeaId = "",
  initialBotIdeaId = "",
}: SessionProviderProps) {
  const [id, setId] = useState<string>(session.id || "");
  const [botIdeaId, setBotIdeaId] = useState<string>(initialBotIdeaId || "");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [title, setTitle] = useState<string>(session.title || "");
  const [streamOfThought, setStreamOfThought] = useState<string[]>([]);
  const [challenge, setChallenge] = useState<string>(session.challenge || "");
  const [challengeAccepted, setChallengeAccepted] = useState<boolean>(false);
  const [inputState, setInputState] = useState<string>(
    session.inputState || "challenge"
  );
  const [stream, setStream] = useState<StreamEntry[]>(session.stream || []);
  const [actions, setActions] = useState<StreamEntry[]>(session.actions || []);
  const [streamAccepted, setStreamAccepted] = useState<boolean>(false);
  const [state, setState] = useState<string>("start");
  const [sessionState, setSessionState] = useState<string>(
    session.sessionState || "challenge"
  );
  const [challengeData, setChallengeData] = useState<{
    whatIfWord: string;
    whatIfImage: string;
    whatIfImageWord: string;
    idea: string;
  }>({
    whatIfWord: session.whatIfWord || "",
    whatIfImage: session.whatIfImage || "",
    whatIfImageWord: session.whatIfImageWord || "",
    idea: session?.ideas?.[0] || "",
  });
  const [personality, setPersonality] = useState<string>(serverPersonality);
  const [selectedIdea, setSelectedIdea] = useState<string>(
    selectedIdeaId || ""
  );
  const [showHistory, setShowHistory] = useState(false);
  const [challengeIsPublic, setChallengeIsPublic] = useState(
    session.isPublic || false
  );
  const [shownEncouragement, setShownEncouragement] = useState(false);
  const [shownCards, setShownCards] = useState(false);
  const randomLine =
    "Study the image card and describe what emotions, memories, or sensations it conjures up in the textbox.";

  return (
    <SessionContextProvider
      value={{
        id,
        setId,
        botIdeaId,
        setBotIdeaId,
        title,
        setTitle,
        isLoading,
        setIsLoading,
        streamOfThought,
        setStreamOfThought,
        challenge,
        setChallenge,
        challengeAccepted,
        setChallengeAccepted,
        inputState,
        setInputState,
        stream,
        setStream,
        actions,
        setActions,
        streamAccepted,
        setStreamAccepted,
        state,
        setState,
        sessionState,
        setSessionState,
        shownEncouragement,
        setShownEncouragement,
        shownCards,
        setShownCards,
        challengeData,
        setChallengeData,
        personality,
        setPersonality,
        selectedIdea,
        setSelectedIdea,
        showHistory,
        setShowHistory,
        challengeIsPublic,
        setChallengeIsPublic,
        randomLine,
      }}
    >
      {children}
    </SessionContextProvider>
  );
}

export function useSession() {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
}
