import React, { useState } from "react";
import * as Popover from "@radix-ui/react-popover";
import { Toggle } from "@radix-ui/react-toggle";
import { Share, Copy, Share2, Shield, ExternalLink } from "lucide-react";
import { Tooltip } from "@/components/feedback/Tooltip";
import { If } from "react-haiku";
import * as Toast from "@radix-ui/react-toast";
import { ShieldOff } from "react-feather";

const ShareMenu = ({
  id = "",
  initialIsPublic = false,
  onTogglePublic = () => {},
  isInList = true,
}) => {
  const url = `${process.env.NEXT_PUBLIC_URL || "https://thinknado.com"}/shared/${id}`;
  const [isPublic, setIsPublic] = useState(initialIsPublic);
  const [isCopied, setIsCopied] = useState(false);

  const handleToggle = async (checked) => {
    setIsPublic(checked);
    await fetch(`/app/api/challenges/${id}`, {
      method: "PUT",
      body: JSON.stringify({ isPublic: checked }),
      headers: {
        "Content-Type": "application/json",
      },
    });
    onTogglePublic(id, checked);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(url).then(() => {
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 10000);
    });
  };

  return (
    <>
      <Popover.Root>
        <Popover.Trigger asChild>
          <button className="p-2 rounded-full focus:outline-none focus:ring-2 focus:ring-[#836DDB]">
            <Tooltip text="Make your challenge public and share it with the world">
              <Share2
                className={
                  isInList ? "w-5 h-5 text-[#1f9fbb]" : "w-7 h-7 text-pink"
                }
                strokeWidth={isInList ? 3 : 1.5}
              />
            </Tooltip>
          </button>
        </Popover.Trigger>

        <Popover.Portal>
          <Popover.Content
            className="w-[320px] max-w-full bg-white text-black border-black border-2 rounded-md shadow-lg px-0 py-4 z-[53] stack gap-4"
            sideOffset={5}
            side="top"
          >
            <div className="flex justify-between items-center px-6">
              <h2 className="text-2xl">Share</h2>

              <div className="text-purple">
                <If isTrue={isPublic}>
                  <Tooltip text="Your session is available to anyone with the link.">
                    <ShieldOff />
                  </Tooltip>
                </If>
                <If isTrue={!isPublic}>
                  <Tooltip text="Your session is only available to you.">
                    <Shield />
                  </Tooltip>
                </If>
              </div>
            </div>
            <div className="stack gap-4  px-6">
              <h3 className="text-[#5b5b5b] font-medium">Status</h3>
              <div className="flex items-center outline-none cursor-default">
                <Toggle
                  pressed={isPublic}
                  onPressedChange={handleToggle}
                  className="mr-2 w-12 h-6 bg-gray rounded-full relative"
                >
                  <div
                    className={`w-5 h-5 rounded-full absolute top-[2px] left-[3px] transition-transform ${isPublic ? "bg-[#836DDB] transform translate-x-6" : "bg-white"}`}
                  />
                </Toggle>
                <span className="text-sm">
                  {isPublic ? "Public" : "Private"}
                </span>
              </div>
            </div>

            <div className="flex justify-between items-center pt-4 border-t-black border-t-2  px-6">
              <If isTrue={!isPublic}>
                <span className="text-sm text-[#5b5b5b]">
                  Make your session public to share it.
                </span>
              </If>
              <If isTrue={isPublic}>
                <a
                  className="flex items-center text-sm text-[#836DDB] hover:underline font-medium"
                  href={url}
                >
                  <ExternalLink className="w-4 h-4 mr-2" strokeWidth={3} />
                  View Session
                </a>
                <button
                  className="flex items-center text-sm text-[#836DDB] hover:underline font-medium"
                  onClick={handleCopyLink}
                >
                  <Copy className="w-4 h-4 mr-2" strokeWidth={3} />
                  Copy link
                </button>
              </If>
            </div>

            <Popover.Arrow className="fill-black" />
          </Popover.Content>
        </Popover.Portal>
      </Popover.Root>

      <Toast.Root
        className="bg-black text-white rounded-md shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] p-[15px] grid [grid-template-areas:_'title_action'_'description_action'] grid-cols-[auto_max-content] gap-x-[15px] items-center data-[state=open]:animate-slideIn data-[state=closed]:animate-hide data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] data-[swipe=cancel]:translate-x-0 data-[swipe=cancel]:transition-[transform_200ms_ease-out] data-[swipe=end]:animate-swipeOut"
        open={isCopied}
        onOpenChange={setIsCopied}
      >
        <Toast.Title className="[grid-area:_title] mb-[5px] font-bold text-slate12 text-[15px] uppercase text-white">
          Success
        </Toast.Title>
        <Toast.Description asChild>
          <p className="text-gray text-sm">
            Your link has been copied to the clipboard.
          </p>
        </Toast.Description>
        <Toast.Action className="[grid-area:_action]" asChild altText="Close">
          <button className="inline-flex items-center justify-center rounded font-medium text-xs px-[10px] leading-none h-[25px] bg-pink text-white ">
            Close
          </button>
        </Toast.Action>
      </Toast.Root>
    </>
  );
};

export default ShareMenu;
