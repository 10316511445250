import { format } from "date-fns";

const FormattedDate = ({ date = "", className = "" }) => {
  return (
    <time dateTime={date} className={className}>
      {format(new Date(date), "LLL	d, yyyy")}
    </time>
  );
};

export default FormattedDate;
